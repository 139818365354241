@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary-50: 243 244 246;
  --color-primary-100: 231 232 236;
  --color-primary-200: 195 198 208;
  --color-primary-300: 159 163 180;
  --color-primary-400: 87 94 123;
  --color-primary-500: 15 25 67;
  --color-primary-600: 14 23 60;
  --color-primary-700: 9 15 40;
  --color-primary-800: 7 11 30;
  --color-primary-900: 5 8 20;
}

a {
  text-decoration: underline;
}

.code-bounce-from-right-animation {
  animation: bounce-from-right 0.5s ease-in-out;
  margin: 0 8px;
  width: 12px;
}

@keyframes bounce-from-right {
  0% {
    margin: 0 0px;
    width: 0;
    opacity: 0;
    transform: translateX(100%);
  }
  40% {
    margin: 0 8px;
    width: 12px;
    opacity: 1;
    transform: translateX(-10%);
  }
  70% {
    transform: translateX(5%);
  }
  85% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

.shake-animation {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}